<template>
  <div v-loading="loading" v-if="hasPower">
    <div style="padding-bottom:44px">
      <van-field label="序列号" v-model="zhcs_step1.serialNumber" :readonly="status!=0">
        <template #right-icon>
          <van-tag type="warning">{{jieMa}}</van-tag>
        </template>
      </van-field>
      <van-field label="备注" v-model="zhcs_step1.remark" :readonly="status!=0"></van-field>
    </div>
    <div class="footer">
      <van-button :disabled="status!=0" type="info" round block @click="handleSave">保存</van-button>
      <van-button :disabled="status!=0" type="danger" round block @click="void_ZHCS_SignNumber">作废</van-button>
    </div>
  </div>
  <van-empty v-else description="无权限"></van-empty>
</template>
<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
export default {
  data() {
    return {
      zhcs_step1: { SerialNumber: "" },
      loading: false,
      status: 0,
      hasPower: false,
      jieMa: "",
    };
  },
  methods: {
    get_zhcs_step1(signNumberId, status) {
      let that = this;
      that.loading = true;
      that.status = status;
      that.axios
        .post("ZHCS/Get_ZHCS_Step1", { onlyInt: signNumberId })
        .then(function (response) {
          that.loading = false;
          that.zhcs_step1 = response.data.data;
        });
    },

    handleSave() {
      let that = this;
      that.axios
        .all([that.save_ZHCS_Step1(), that.registerSerialNumber()])
        .then(
          that.axios.spread(function (response1, response2) {
            that.zhcs_step1 = response1.data.data;
            if (response1.data.code == 201) {
              that.$notify({ type: "warning", message: response1.data.msg });
            } else if (response2.data.code == 201) {
              that.$notify({ type: "warning", message: response2.data.msg });
            } else {
              that.$notify({ type: "success", message: response1.data.msg });
            }
            if (response2.data.code == 101) {
              that.jieMa = response2.data.msg;
            }
          })
        );
    },

    save_ZHCS_Step1() {
      let that = this;
      return that.axios.post("ZHCS/save_ZHCS_Step1", that.zhcs_step1);
    },
    registerSerialNumber() {
      let that = this;
      return that.axios.post("Base_Device/Register", {
        productName: "综合测试仪",
        productId: 37,
        serialNumber: that.zhcs_step1.serialNumber,
        deviceType: "ZHCSY1",
        companyName: "",
        ifPrint: false,
      });
    },
    void_ZHCS_SignNumber() {
      let that = this;
      that.$dialog
        .confirm({ message: "确定要作废吗？" })
        .then(function () {
          that.axios
            .post("ZHCS/void_ZHCS_SignNumber", {
              onlyInt: that.zhcs_step1.id,
            })
            .then(function (response) {
              if (response.data.code == 101) {
                that.status = that.$parent.zhcs_SignNumber.status = 2;
                that.$notify({ type: "success", message: response.data.msg });
              } else {
                that.$notify({ type: "warning", message: response.data.msg });
              }
            });
        })
        .catch(() => {});
    },
  },
  mounted() {
    let that = this;
    //获取权限
    that.axios
      .post("Base/HasPower", { powerKey: "综合测试仪_江阴" })
      .then(function (response) {
        that.hasPower = response.data.data;
      });
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  width: 100%;
  left: 0px;
  bottom: 0px;
}
</style>