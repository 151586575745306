<template>
  <div v-loading="loading" v-if="hasPower">
    <div style="padding-bottom:44px">
      <van-field label="型号" v-model="zhcs_step3.xingHao" :readonly="status!=0"></van-field>
      <van-field label="编号" v-model="zhcs_step3.biaoHao" :readonly="status!=0"></van-field>
      <van-field label="公司名称" center><template #input>
          <el-select v-model="zhcs_step3.companyName" filterable style="width:100%">
            <el-option v-for="(item,index) in companyNameList" :key="index" :value="item.dataText"></el-option>
          </el-select>
        </template></van-field>
      <van-field label="经销商" center>
        <template #input>
          <el-select v-model="zhcs_step3.agentName" filterable style="width:100%">
            <el-option v-for="(item,index) in agentList" :key="index" :value="item.contactName" @click.native="handleSelect(index)"></el-option>
          </el-select>
        </template>
      </van-field>
      <van-field label=" 经销商电话" v-model="zhcs_step3.agentPhone" :readonly="status!=0">
      </van-field>

      <van-field label="日期" v-model="zhcs_step3.riQi" is-link readonly @click="popupVisible=status==0"></van-field>
      <van-field label="备注" v-model="zhcs_step3.remark" :readonly="status==1"></van-field>
    </div>

    <div class="footer">
      <van-button type="info" round block @click="save_ZHCS_Step3" :disabled="status!=0">保存</van-button>
      <van-button v-if="status==0&&hasJieDan" type="warning" round block @click="end_ZHCS_SignNumber">结单</van-button>
      <van-button v-else-if="status==1&&hasJieDan" type="primary" round block @click="cancel_end_ZHCS_SignNumber">取消结单</van-button>
    </div>
    <van-popup v-model="popupVisible" position="bottom">
      <van-datetime-picker type="year-month" title="选择年月" v-model="dtpValue" @cancel="popupVisible=false" @confirm="confirmRiQi"></van-datetime-picker>
    </van-popup>
  </div>
  <van-empty v-else description="无权限"></van-empty>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import Moment from "moment";
import "vant/lib/index.css";
Vue.use(Vant);
export default {
  data() {
    return {
      popupVisible: false,
      zhcs_step3: { agentPhone: "" },
      dtpValue: new Date(),
      loading: false,
      status: 0,
      hasPower: false,
      hasJieDan: false,
      companyNameList: null,
      agentList: null,
    };
  },
  methods: {
    handleSelect(v) {
      let that = this;
      that.zhcs_step3.agentPhone = that.agentList[v].phone;
    },
    confirmRiQi(v) {
      let that = this;
      that.zhcs_step3.riQi = Moment(v).format("yyyy-MM");
      that.popupVisible = false;
    },
    get_zhcs_step3(signNumberId, status) {
      let that = this;
      that.status = status;
      that.loading = true;
      that.axios
        .post("ZHCS/Get_ZHCS_Step3", { onlyInt: signNumberId })
        .then(function (response) {
          that.loading = false;
          that.zhcs_step3 = response.data.data;
          that.dtpValue = Moment(that.zhcs_step3.riQi).toDate();
          that.zhcs_step3.riQi = Moment(that.zhcs_step3.riQi).format("yyyy-MM");
        });
    },
    save_ZHCS_Step3() {
      let that = this;
      that.axios
        .post("ZHCS/save_ZHCS_Step3", that.zhcs_step3)
        .then(function (response) {
          that.zhcs_step3 = response.data.data;
          that.zhcs_step3.riQi = Moment(that.zhcs_step3.riQi).format("yyyy-MM");
          if (response.data.code == 101) {
            that.$notify({ type: "success", message: response.data.msg });
          } else {
            that.$notify({ type: "warning", message: response.data.msg });
          }
        });
    },
    end_ZHCS_SignNumber() {
      let that = this;
      that.$dialog
        .confirm({ message: "确定要结单吗？" })
        .then(function () {
          that.axios
            .post("ZHCS/end_ZHCS_SignNumber", {
              onlyInt: that.zhcs_step3.id,
            })
            .then(function (response) {
              if (response.data.code == 101) {
                that.status = that.$parent.zhcs_SignNumber.status = 1;
                that.$notify({ type: "success", message: response.data.msg });
              } else {
                that.$notify({ type: "warning", message: response.data.msg });
              }
            });
        })
        .catch(() => {});
    },
    cancel_end_ZHCS_SignNumber() {
      let that = this;
      that.$dialog
        .confirm({ message: "确定要取消结单吗？" })
        .then(function () {
          that.axios
            .post("ZHCS/cancel_end_ZHCS_SignNumber", {
              onlyInt: that.zhcs_step3.id,
            })
            .then(function (response) {
              if (response.data.code == 101) {
                that.status = that.$parent.zhcs_SignNumber.status = 0;
                that.$notify({ type: "success", message: response.data.msg });
              } else {
                that.$notify({ type: "warning", message: response.data.msg });
              }
            });
        })
        .catch(() => {});
    },
  },
  mounted() {
    let that = this;
    //获取权限
    that.axios
      .post("Base/HasPower", { powerKey: "综合测试仪_出货" })
      .then(function (response) {
        that.hasPower = response.data.data;
      });
    that.axios
      .post("Base/HasPower", { powerKey: "综合测试仪_结单" })
      .then(function (response) {
        that.hasJieDan = response.data.data;
      });
    //获取公司名称
    that.axios
      .post("Base_SystemBaseData/GetListByDataType", {
        dataType: "贴牌公司名称",
      })
      .then(function (response) {
        that.companyNameList = response.data.data;
      });
    //获取经销商
    that.axios.post("Base_Agent/GetListAll").then(function (response) {
      that.agentList = response.data.data;
    });
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
</style>