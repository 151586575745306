import { render, staticRenderFns } from "./Wap_ZHCS_Step3.vue?vue&type=template&id=479d1d35&scoped=true&"
import script from "./Wap_ZHCS_Step3.vue?vue&type=script&lang=js&"
export * from "./Wap_ZHCS_Step3.vue?vue&type=script&lang=js&"
import style0 from "./Wap_ZHCS_Step3.vue?vue&type=style&index=0&id=479d1d35&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479d1d35",
  null
  
)

export default component.exports