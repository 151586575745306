<template>
  <div v-loading="loading" v-if="hasPower">
    <el-col :span="12" style="padding-bottom:44px">
      <van-field label="13V(1)">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence1" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="8V">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence3" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="5V(1)">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence5" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="蓝色灯闪烁">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence7" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="版本号">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence9" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="开关机">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence11" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="力值1传感器">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence13" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="位移1传感器">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence15" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="电机下降">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence17" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="时间日期">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence19" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="升级测试">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence21" shape="square" :disabled="status!=0" />
        </template>
      </van-field>

    </el-col>
    <el-col :span="12" style="padding-bottom:44px">
      <van-field label="13V(2)">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence2" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="3.3V">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence4" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="5V(2)">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence6" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="屏幕显示">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence8" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="按键测试">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence10" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="力值1传感器">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence12" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="位移2传感器">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence14" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="电机上升">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence16" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="打印机">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence18" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="联网测试">
        <template #input>
          <van-checkbox v-model="zhcs_step2.sequence20" shape="square" :disabled="status!=0" />
        </template>
      </van-field>
      <van-field label="备注" v-model="zhcs_step2.remark" :readonly="status!=0">
      </van-field>
    </el-col>
    <el-button type="primary" class="footer1" icon="el-icon-view" circle @click="handlePicClick"></el-button>
    <div class="footer">
      <van-button type="info" round block @click="save_ZHCS_Step2" :disabled="status!=0">保存</van-button>
      <van-button :disabled="status!=0" type="danger" round block @click="void_ZHCS_SignNumber">作废</van-button>
    </div>
  </div>
  <van-empty v-else description="无权限"></van-empty>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import { ImagePreview } from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
export default {
  data() {
    return {
      zhcs_step2: {},
      loading: false,
      status: 0,
      hasPower: false,
    };
  },
  methods: {
    handlePicClick() {
      ImagePreview(["./images/ZHCS.jpg"]);
    },
    get_zhcs_step2(signNumberId, status) {
      let that = this;
      that.status = status;
      that.loading = true;
      that.axios
        .post("ZHCS/Get_ZHCS_Step2", { onlyInt: signNumberId })
        .then(function (response) {
          that.loading = false;
          that.zhcs_step2 = response.data.data;
        });
    },
    save_ZHCS_Step2() {
      let that = this;
      that.axios
        .post("ZHCS/save_ZHCS_Step2", that.zhcs_step2)
        .then(function (response) {
          that.zhcs_step2 = response.data.data;
          if (response.data.code == 101) {
            that.$notify({ type: "success", message: response.data.msg });
          } else {
            that.$notify({ type: "warning", message: response.data.msg });
          }
        });
    },
    void_ZHCS_SignNumber() {
      let that = this;
      that.$dialog
        .confirm({ message: "确定要作废吗？" })
        .then(function () {
          that.axios
            .post("ZHCS/void_ZHCS_SignNumber", {
              onlyInt: that.zhcs_step2.id,
            })
            .then(function (response) {
              if (response.data.code == 101) {
                that.status = that.$parent.zhcs_SignNumber.status = 2;
                that.$notify({ type: "success", message: response.data.msg });
              } else {
                that.$notify({ type: "warning", message: response.data.msg });
              }
            });
        })
        .catch(() => {});
    },
  },
  mounted() {
    let that = this;
    //获取权限
    that.axios
      .post("Base/HasPower", { powerKey: "综合测试仪_沧州" })
      .then(function (response) {
        that.hasPower = response.data.data;
      });
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
.footer1 {
  position: fixed;
  left: 10px;
  bottom: 50px;
}
</style>