<template>
  <div>
    <div v-show="power!=-1">
      <van-steps :active="active" @click-step="handleClickStep">
        <van-step>江阴</van-step>
        <van-step>沧州</van-step>
        <van-step>出货</van-step>
      </van-steps>
      <van-field label="标签码" v-model="zhcs_SignNumber.signNumber" :readonly="true">
        <template #extra v-if="zhcs_SignNumber.status==2">
          <van-tag type="danger">作废</van-tag>
        </template>
        <template #extra v-else-if="zhcs_SignNumber.status==1">
          <van-tag type="primary">结单</van-tag>
        </template>
      </van-field>
      <Wap_ZHCS_Step1 v-show="active==0" ref="step1" :signNumber="$route.query.signNumber"></Wap_ZHCS_Step1>
      <Wap_ZHCS_Step2 v-show="active==1" ref="step2" :signNumber="$route.query.signNumber"></Wap_ZHCS_Step2>
      <Wap_ZHCS_Step3 v-show="active==2" ref="step3" :signNumber="$route.query.signNumber"></Wap_ZHCS_Step3>

    </div>

    <van-empty v-show="power==-1"><template #description>
        {{$refs.step3?$refs.step3.zhcs_step3.companyName:""}}
        <div class="textCenter"><a :href="'tel:'+($refs.step3?$refs.step3.zhcs_step3.agentPhone:'')">{{$refs.step3?$refs.step3.zhcs_step3.agentPhone:""}}</a></div>
      </template>
    </van-empty>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import Wap_ZHCS_Step1 from "./Wap_ZHCS_Step1";
import Wap_ZHCS_Step2 from "./Wap_ZHCS_Step2";
import Wap_ZHCS_Step3 from "./Wap_ZHCS_Step3";
import "vant/lib/index.css";
Vue.use(Vant);
export default {
  components: { Wap_ZHCS_Step1, Wap_ZHCS_Step2, Wap_ZHCS_Step3 },
  data() {
    return {
      active: 0,
      zhcs_SignNumber: {},
      power: -1,
    };
  },
  methods: {
    handleClickStep(index) {
      let that = this;
      that.active = index;
      if (that.active == 0) {
        that.$refs.step1.get_zhcs_step1(
          that.zhcs_SignNumber.id,
          that.zhcs_SignNumber.status
        );
      } else if (that.active == 1) {
        that.$refs.step2.get_zhcs_step2(
          that.zhcs_SignNumber.id,
          that.zhcs_SignNumber.status
        );
      } else if (that.active == 2) {
        that.$refs.step3.get_zhcs_step3(
          that.zhcs_SignNumber.id,
          that.zhcs_SignNumber.status
        );
      }
    },
    init_SignNumber() {
      let that = this;
      that.axios
        .post("/ZHCS/Init_SignNumber", {
          onlyText: that.$route.query.signNumber,
        })
        .then(function (response) {
          that.active = response.data.msg;
          that.zhcs_SignNumber = response.data.data;
          setTimeout(function () {
            that.handleClickStep(2);
            //获取是江阴。沧州。出货的权限
            that.axios.post("ZHCS/GetPowerForView").then(function (response) {
              that.power = response.data.data;
              that.handleClickStep(that.power);
            });
          }, 500);
        });
    },
  },
  mounted() {
    let that = this;
    document.title = "综合测试仪";
    that.init_SignNumber();
  },
};
</script>

<style scoped>
.textCenter {
  text-align: center;
}
</style>